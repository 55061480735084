import { InvoiceGroup } from '../../shared/invoice-group/invoice-group.model';
import { PagedData } from '../../shared/paginator/pager.model';

export interface MeteringPointData {
  status: Status;
  page: PagedData<MeteringPoint>;
}

export interface Status {
  active: number;
  inactive: number;
  new: number;
}

export interface MeteringPoint {
  addressFreeForm: string;
  administrationFee: number;
  administrationFeeCustomer: string;
  autoInvoice: boolean;
  balanceSupplierGLN: string;
  blockedForSwitching: boolean;
  bnr: string;
  buildingNumber: string;
  chargeTenantForProduction: boolean;
  city: string;
  citySubDivisionName: string;
  connectorID: number;
  connectorType: number;
  consumptionCode: string;
  contractEndDateMs: number | Date;
  contractID: number;
  contractName: string;
  contractStartDateMs: number | Date;
  contractTypeID: number;
  country: string;
  current: number;
  customerID: string;
  customerName: string;
  dataAccessMethod: string;
  dataProvider: string;
  deliveryObligationDateMs: number;
  description: string;
  elCertificateShare: number;
  elFeeShare: number;
  energyCompensationID: number;
  energyMeteringMethod: string;
  energyMeteringType: string;
  energySource: string;
  enovaFee: number;
  enovaFeeType: string;
  estimatedConsumption: number; // Versioned field
  estimatedConsumptionCalcMethod: string;
  externalDescription: string;
  floorIdentification: string;
  fnr: string;
  fundID: number;
  gnr: string;
  gridPeakAddon: number;
  gridPeakOverride: number;
  gridReactiveOverride: number;
  gridStartDateMs: number | Date;
  gridTariffID: number;
  industrialCode: string; // Versioned field
  installationID: number;
  installedCapacity: number;
  internalDeviceID: string;
  invoiceGroup: InvoiceGroup;
  lastMeterReadingDateMs: number | Date;
  loadLimit: number;
  locationLatitude: number;
  locationLongitude: number;
  meterAccuracy: number;
  meterConversionFactor: number;
  meterGridAreaID: number;
  meteringPointContractBalanceMaxAmount: number;
  meteringPointContractBalanceRate: number;
  meteringPointContractEndDateMs: number | Date;
  meteringPointContractEndDateStatus: string;
  meteringPointContractID: number;
  meteringPointContractPowerVendor: string;
  meteringPointContractReleaseEndDateMs: number | Date;
  meteringPointContractStartDateMs: number | Date;
  meteringPointContractStartDateStatus: string;
  meteringPointContractTerminationReason: string;
  meteringPointID: string;
  meteringPointName: string;
  meteringPointRegisterID: number;
  meteringPointTag: string;
  meteringPointType: string;
  meterLocation: string;
  meterMeasurementMethod: string;
  meterReadingFrequencyDuration: number;
  meterReadingStartDateMs: number | Date;
  meterRolloverValue: number;
  meterVendorModel: string;
  municipalityCode: string;
  numberOfDigits: number;
  official: boolean;
  physicalStatusType: string;
  postCode: string;
  productionGridTariffID: number;
  priceHedgingTenant: boolean;
  priority: string;
  reducedTaxPercentage: number; // Versioned field
  roomIdentification: string;
  settlementMethod: string;
  snr: string;
  stipulationType: string;
  streetCode: string;
  streetName: string;
  subTypeConsumption: string;
  taxCategoryCode: string; // Versioned field
  vatCode: string;
  vendor: string;
  voltage: number;

  address?: string; // Only when fetching list of metering points
  reload?: boolean; // Only given by el-hub lookup to indicate we should reload codes data
  meteringPointNumber: string;
  status: string;
  subTypeProduction: string;
  fundName: string;
  gridOwnerName: string;
  locationPlacement: string;

  canClone: boolean;
  _active?: boolean;
}

export interface ReattachedMeteringPoint {
  meteringPointContractID: number;
  contractID: number;
  meteringPointRegisterID: number;
  startDateMs: number;
  startDateStatus: string;
  endDateMs: number;
  endDateStatus: string;
  terminationReason: string;
  finalizedDateMs: number;
  gridStartDateMs: number;
  releaseEndDateMs: number;
  powerVendor: string;
  balanceMaxAmount: number;
  balanceRate: number;
  meteringPointID: string;
  active: boolean;
}

export const CategorizedMeter = {
  '01-Meteringpoint': [
    'meteringPointID',
    'meteringPointName',
    'energyMeteringType',
    'estimatedConsumption',
    'description',
    'externalDescription',
    'internalDeviceID',
    'meterLocation',
    'official',
    'installationID',
    'meteringPointRegisterID',
  ],
  '02-Contract': [
    'customerID',
    'customerName',
    'contractID',
    'contractStartDate',
    'contractEndDate',
    'contractName',
    'meteringPointContractID',
    'meteringPointContractStartDateMs',
    'meteringPointContractStartDateStatus',
    'meteringPointContractEndDateMs',
    'meteringPointContractEndDateStatus',
    'gridStartDateMs',
    'meteringPointContractTerminationReason',
  ],
  '03-Address': [
    'streetName',
    'buildingNumber',
    'postCode',
    'city',
    'country',
    'gnr',
    'bnr',
    'floorIdentification',
    'roomIdentification',
    'addressFreeForm',
    'streetCode',
    'citySubDivisionName',
    'municipalityCode',
    'address',
    'locationLatitude',
    'locationLongitude',
    'balanceSupplierGLN',
  ],
  '04-Grid owner': ['meterGridAreaID', 'gridTariffID', 'gridTariffName', 'gridTariffCode', 'gridOwnerName'],
  '05-Details': [
    'meteringPointType',
    'energyMeteringMethod',
    'energySource',
    'physicalStatusType',
    'estimatedConsumptionCalcMethod',
    'priority',
    'meterReadingStartDateMs',
    'meterReadingFrequencyDuration',
    'settlementMethod',
    'blockedForSwitching',
    'subTypeConsumption',
    'status',
    'estimatedConsumption',
    'installationID',
  ],
  '06-Financial': [
    'industrialCode',
    'vatCode',
    'enovaFee',
    'enovaFeeType',
    'elFeeShare',
    'elCertificateShare',
    'consumptionCode',
    'taxCategoryCode',
    'taxCategoryCodeCalc',
    'reducedTaxPercentage',
    'fundID',
    'fundName',
    'stipulationType',
    'invoiceGroup',
    'energyCompensationID',
    'autoInvoice',
  ],
  '07-Technical': [
    'meteringPointTag',
    'installedCapacity',
    'loadLimit',
    'current',
    'voltage',
    'vendor',
    'meterConversionFactor',
    'meterVendorModel',
    'dataAccessMethod',
    'dataProvider',
    'connectorType',
    'connectorID',
    'numberOfDigits',
    'energyMeteringType',
    'meterMeasurementMethod',
    'meterAccuracy',
    'meterRolloverValue',
    'version',
  ],
};

export const MeterKeys: (keyof MeteringPoint)[] = [
  'address',
  'addressFreeForm',
  'autoInvoice',
  'balanceSupplierGLN',
  'blockedForSwitching',
  'bnr',
  'buildingNumber',
  'city',
  'citySubDivisionName',
  'connectorID',
  'connectorType',
  'consumptionCode',
  'contractEndDateMs',
  'contractID',
  'contractName',
  'contractStartDateMs',
  'country',
  'current',
  'customerID',
  'customerName',
  'dataAccessMethod',
  'dataProvider',
  'description',
  'elCertificateShare',
  'elFeeShare',
  'energyMeteringMethod',
  'energyMeteringType',
  'energySource',
  'enovaFee',
  'enovaFeeType',
  'estimatedConsumption',
  'estimatedConsumptionCalcMethod',
  'externalDescription',
  'floorIdentification',
  'fnr',
  'fundID',
  'fundName',
  'gnr',
  'gridOwnerName',
  'gridTariffID',
  'industrialCode',
  'installedCapacity',
  'internalDeviceID',
  'invoiceGroup',
  'loadLimit',
  'locationLatitude',
  'locationLongitude',
  'locationPlacement',
  'meterAccuracy',
  'meterConversionFactor',
  'meterGridAreaID',
  'meteringPointContractEndDateMs',
  'meteringPointContractID',
  'meteringPointContractStartDateMs',
  'meteringPointContractStartDateStatus',
  'meteringPointContractEndDateStatus',
  'meteringPointContractTerminationReason',
  'meteringPointID',
  'meteringPointName',
  'meteringPointNumber',
  'meteringPointRegisterID',
  'meteringPointTag',
  'meteringPointType',
  'meterLocation',
  'meterMeasurementMethod',
  'meterReadingFrequencyDuration',
  'meterReadingStartDateMs',
  'meterRolloverValue',
  'meterVendorModel',
  'municipalityCode',
  'numberOfDigits',
  'official',
  'physicalStatusType',
  'postCode',
  'priority',
  'reducedTaxPercentage',
  'roomIdentification',
  'settlementMethod',
  'snr',
  'streetCode',
  'streetName',
  'subTypeConsumption',
  'subTypeProduction',
  'taxCategoryCode',
  'vatCode',
  'vendor',
  'voltage',
];

export interface MeterFilter {
  [key: string]: string | number | boolean;
}

export interface MeteringPointReadings {
  meterConversionFactor?: number;
  customerID: string;
  customerName: string;
  meteringPointID: string;
  meteringPointName: string;
  meteringPointReadings: MeterReading[];
}

export interface MeterReading {
  meteringPointReadingID: number;
  meteringPointRegisterID: number;
  endDateMs: number | Date;
  value: number;
  meterReadingEnd: number;
}

export interface HourlyValue {
  timestampMs: number;
  quantity: number;
}

export interface PostHourlyValue {
  fromMs: number;
  toMs?: number;
  value: number;
  direction?: string;
  qualityCode?: string;
  energyProductionCode?: string;
}

/** To be transformed to InvoiceLines */
export interface MeterCost {
  meteringPointContractID: number;
  powerCost: number;
  elcertificate: number;
  gridFixedCost: number;
  gridEnergyCost: number;
  gridPeakCost: number;
  gridReactiveCost: number;
  tax: number;
  consumption: number;
  consumptionGrid: number;
  consumptionNight: number;
  consumptionStipulated: number;
  priceHedgingCost: number;
  pctMarkup: number;
  originGuaranteeCost: number;
  energyCompensationCost: number;
  energyRecoveryCustomerCost: number;
  energyRecoveryCompanyCost: number;
  production: number;
  productionPowerPrice: number;
  productionGridPrice: number;
}

/** Reused from invoice.model.ts to print the active tariff calculations */
export { InvoiceCost, InvoiceLineElement, InvoiceLines } from '../invoice/invoice.model';
