import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

export interface GlobalConstants {
  [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  constructor(private http: HttpClient) {}

  getGlobalValues() {
    return this.http.get<GlobalConstants>(`/api/bs/Constants`);
  }
}
